import * as React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

// , location
const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  // location = { location }
  return (
    <Layout title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <div className="wrapper">
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
          </div>
        </header>

        <div className="wrapper">
          <section
            className="body"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </div>

        {/* <footer>
          <Bio />
        </footer> */}
      </article>
    </Layout>
  )
}
BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  // location: PropTypes.string.isRequired,
}

// <nav className="blog-post-nav">
//   <ul
//     style={{
//       display: `flex`,
//       flexWrap: `wrap`,
//       justifyContent: `space-between`,
//       listStyle: `none`,
//       padding: 0,
//     }}
//   >
//     <li>
//       {previous && (
//         <Link to={previous.fields.slug} rel="prev">
//           ← {previous.frontmatter.title}
//         </Link>
//       )}
//     </li>
//     <li>
//       {next && (
//         <Link to={next.fields.slug} rel="next">
//           {next.frontmatter.title} →
//         </Link>
//       )}
//     </li>
//   </ul>
// </nav>

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
